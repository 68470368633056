import _gOPD from "./gOPD";
var exports = {};
/** @type {import('.')} */
var $gOPD = _gOPD;
if ($gOPD) {
  try {
    $gOPD([], "length");
  } catch (e) {
    // IE 8 has a broken gOPD
    $gOPD = null;
  }
}
exports = $gOPD;
export default exports;